import type { ComputedRef, Ref } from 'vue'
import type { ComposerTranslation } from 'vue-i18n-bridge'
import type VueRouter from 'vue-router'

import type { V2AccountDataType } from '@/v1.5/features/auth/types'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { menteeMatchConfirmMutation } from '@/v1.5/features/matching/api'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import type { ToastType } from '@/v1.5/types'
import { ROUTES } from '@/v1.5/utils/config/constants'
import { resetMainQueriesCache } from '@/v1.5/utils/lib/vue-query'

export async function confirmMatchFn({
  toast,
  router,
  t,
  isSubmittingRef,
  matchedGroupIdRef,
  selectedMentorId,
  selectedProgramIdRef,
  accountRef,
}: {
  toast?: ToastType
  router?: VueRouter
  t: ComposerTranslation
  isSubmittingRef: Ref<boolean>
  matchedGroupIdRef: Ref<string | null | undefined>
  selectedMentorId: string
  selectedProgramIdRef: Ref<string | null>
  accountRef: ComputedRef<Partial<V2AccountDataType> | undefined>
}) {
  try {
    isSubmittingRef.value = true

    const matchingResponse = await menteeMatchConfirmMutation({
      menteeId: accountRef.value!.id!,
      mentorId: selectedMentorId,
      groupId: matchedGroupIdRef.value!,
      programId: selectedProgramIdRef.value!,
    })

    // if the mentor does not have a group, we add the users inside
    // if (!matchedMentorHasGroupRef.value) {
    // create group
    // const createGroupResponseData = await createGroupMutation({
    //   programId: selectedProgramIdRef.value!,
    // })

    // if (!createGroupResponseData.groupId) {
    //   throw new Error('No group id')
    // }

    // groupId = createGroupResponseData.groupId

    // add mentor to the group
    // await addUserToGroupMutation({
    //   groupId,
    //   userId: matchedMentorIdRef.value!,
    // })
    // }

    // // add mentee to the group
    // await addUserToGroupMutation({
    //   groupId: groupId!,
    //   userId: accountRef.value!.id!,
    // })

    // we now fetch the group created (or matched) group

    toast?.success(t('matching.newMatchingModal.confirmation.success'))
    modalsStore.toggleModal('new-matching', false)

    // we hard reset the cache and invalide the queries to refetch everything in background
    await resetMainQueriesCache(selectedProgramIdRef.value!)

    // we set the new group as selected and redirect to the chat
    groupsStore.setSelectedGroupId(matchingResponse.group.id)
    router?.push(ROUTES.CHAT(matchingResponse.group.conversationId))
  } catch (e: any) {
    if (e.message == 'You can not be matched yet, please wait a few minutes') {
      toast?.error(t('matching.newMatchingModal.confirmation.error'))
    } else {
      toast?.error(t('error.common'))
    }
  } finally {
    isSubmittingRef.value = false
  }
}

// internationalization
export const I18N_LOCAL_STORAGE_KEY = 'ce-app-i18n-locale'
export const I18N_DEFAULT_LOCALE = 'fr'

// all app routes
export const ROUTES = {
  LOGIN: '/auth/login',
  lOGIN_WITH_EMAIL: '/auth/login-with-email',
  LOGIN_WITH_SAML: '/auth/login-with-saml',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  REGISTER: '/auth/register',
  ONBOARDING: '/onboarding',
  DASHBOARD: '/dashboard',
  MESSAGES: (conversationId?: string) => (conversationId ? `/messages/${conversationId}` : '/messages'),
  CHAT: (conversationId?: string) => (conversationId ? `/chat/${conversationId}` : '/chat'),
  SESSIONS: '/sessions',
  USER: (userId: string) => `/user/${userId}`,
  PROFILE: '/profile',
  SETTINGS: '/settings',
  ADMIN: {
    DASHBOARD: '/admin/dashboard',
    GROUPS: '/admin/groups',
    USERS: '/admin/users',
    SESSIONS: '/admin/sessions',
  },
}

// reflect the media queries inside the css
export const BREAKPOINTS = {
  'MOBILE': 375,
  'TABLET': 768,
  'DESKTOP': 1024,
  '2K': 2560,
}

// Phone number modal
export const DO_NOT_ASK_PHONE_NUMBER_AGAIN = 'doNotAskPhoneNumberAgain'

// session review modal on uncompleted session
export const DO_NOT_ASK_REVIEW_AGAIN = 'doNotAskReviewAgain'

// analytics disabled key in order to disable all analytics tools when impersonating a user
export const ANALYTICS_DISABLED_KEY = 'constel-analytics-disabled'

// general constants
export const CONTACT_EMAIL = 'contact@constel-education.com'
export const SAML_URL = 'https://sp.constel-education.com/auth'
export const PROFILE_PICTURE_BUCKET_URL = 'https://profil-picture.s3.fr-par.scw.cloud/'

// sessions constants
export const MAX_SESSIONS_PER_PAGE = 20
export const MAX_USERS_PER_PAGE = 20
export const MAX_GROUPS_PER_PAGE = 20

import router from '@/router'
import v1Store from '@/store'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import { PosthogClient } from '@/v1.5/lib/posthog'
import queryClient from '@/v1.5/lib/vue-query'
import { ANALYTICS_DISABLED_KEY, ROUTES } from '@/v1.5/utils/config/constants'

export default async function logout() {
  console.log('logout')
  v1Store.dispatch('storeAuthLogout')
  programsStore.reset()
  groupsStore.reset()
  await queryClient.cancelQueries()
  await queryClient.clear()
  const hasAnalyticsDisabled = window.localStorage.getItem(ANALYTICS_DISABLED_KEY)

  window.localStorage.clear()

  // if analytics were disabled, keep it disabled even when logging out
  if (hasAnalyticsDisabled) {
    window.localStorage.setItem(ANALYTICS_DISABLED_KEY, 'true')
  }

  PosthogClient.reset() // https://posthog.com/docs/product-analytics/identify#3-reset-after-logout
  router.push(ROUTES.LOGIN).catch(() => {})
}
